







































import { PropType } from 'vue';

import BaseDialog from '@/components/BaseDialog.vue';
import ImageUploader from '@/components/ImageUploader.vue';

import { ValidationRule, ICopyStakeGameItemResponse } from '@/api/schema';
import * as vr from '@/helpers/validation';

const maxFileSize = 1024 * 400;
const imageRequirements = `Image aspect ratio must be 4:3 (recommended dimensions is 600 x 450 pixels) in JPEG, JPG or WEBP (file size – up to 400kb)`;

export default {
  name: 'CopyStakeWhitelistGameDialog',
  components: {
    BaseDialog,
    ImageUploader
  },
  props: {
    value: {
      type: Boolean,
      default: (): boolean => false
    },
    game: {
      type: Object as PropType<ICopyStakeGameItemResponse>,
      default: (): any => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      isFormValid: false,
      uploadImageFile: null,
      imageRequirements,
      maxFileSize
    };
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
    rules(): ValidationRule[] {
      return [
        (v) => !!v || imageRequirements,
        (v) => vr.fileMaxSize(v, maxFileSize, imageRequirements)
      ];
    },
    isEdit(): boolean {
      return !!this.imageUrl;
    },
    imageUrl(): string {
      return this.game?.image || '';
    }
  },
  methods: {
    async handlerOnSubmitForm(): Promise<void> {
      await this.$refs.form.validate();
      if (!this.isFormValid) return;

      this.$emit('submit', this.uploadImageFile);
    },
    handlerOnUploadImage(image: File): void {
      this.uploadImageFile = image;
    },
    closeDialog(): void {
      this.showDialog = false;

      this.$emit('close');
    }
  }
};
